import React, { useState, useEffect } from "react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/16/solid";

import HeaderSocial from "./HeaderSocial";
import HeaderNav from "./HeaderNav";
import HeaderButton from "./HeaderButton";
import HeaderLogo from "./HeaderLogo";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed pt-2 pb-2 w-full h-auto z-50 transition-all duration-300 ${
        isScrolled ? "colorBgBlue shadow-lg" : "bg-gray-900/5"
      }`}
    >
      <div className="flex justify-between items-center p-4 coloBgBlue header">
        <div className="flex items-center">
          <div className="w-8 h-8 flex items-center justify-center md:hidden">
            <Bars3Icon
              onClick={toggleMobileMenu}
              className="text-white  md:hidden"
            />
          </div>
          <HeaderLogo />
        </div>
        <HeaderNav classNav="text-xl hidden md:flex  justify-center items-center " claseUl="flex space-x-4" />
        <HeaderSocial classSoc="flex space-x-4 hidden md:flex justify-center" />
        <HeaderButton classBut="hidden md:block" />
        {isMobileMenuOpen && (
          <div className="fixed inset-0 z-50 colorBgBlue text-white p-4 md:hidden">
            <div className="flex items-center">
              <div className="w-8 h-8 flex items-center justify-center">
                <XMarkIcon
                  onClick={toggleMobileMenu}
                  className="text-white  md:hidden"
                />
              </div>
              <HeaderLogo />
            </div>
            <HeaderNav closeMobileMenu={closeMobileMenu} classNav="mt-4" claseUl="flex flex-col space-y-4 text-3xl" />

            <HeaderSocial classSoc="flex space-x-4 mt-4 pl-8" />
            <HeaderButton classBut="mt-4 inline-block" />
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
