import React, { useEffect, useState } from "react";
import ExcavadoraAnimacion from "../../assets/images/excavadora Animacion.webp";

function AboutUsAnimacion() {
  const [offsetXRegister, setoffsetXRegister] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const vw = window.innerWidth / 100; // Calcula el tamaño de 1vw en pixeles
    let offsetX 
    // Ajuste para dispositivos móviles y tablets

    if (window.innerWidth < 769) {
      offsetX = (scrollY - 1660)/ vw; // Reducir la velocidad en la mitad para dispositivos pequeños
    } else {
      offsetX = (scrollY - 700)/ vw * 2;
    }

    const img = document.querySelector(".movimiento-imagen");
    img.style.transform = `translateX(${offsetX}vw)`;

    setoffsetXRegister(offsetX)
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="h-40">
      <img
        src={ExcavadoraAnimacion}
        className={`${offsetXRegister * (window.innerWidth / 100) + 500 > window.innerWidth ? "hidden" : "block"} mt-10 w-auto h-40 rounded-full movimiento-imagen`}
        alt="Imagen grande"
      />
    </div>
  );
}

export default AboutUsAnimacion;
