import React from 'react';
import Hero from './Hero';
import Valores from './Valores'
import Team from './Team';
import AboutMe from './AboutMe';

function AboutUs() {
  return (
    <>
      <Hero />
      <Valores />
      <AboutMe />
      <Team />
    </>
  );
}

export default AboutUs;
