import React, { useState } from "react";
import BackgroundImage from "../../assets/images/tractor.webp";
import emailjs from "emailjs-com";

const FormData = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_xcltr1n",
        "template_jqjq0f9",
        formData,
        "pvFvNWPgeUFo2TgTS"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Mensaje enviado correctamente");
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Error al enviar el mensaje");
        }
      );
  };

  return (
    <section className="relative bg-dark text-white py-12">
      {/* Imagen de fondo */}
      <div
        className="absolute inset-0 w-full h-full bg-fixed bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-70"></div>

      <div className="relative container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Información de Contacto */}
          <div className="md:w-1/2 text-left mb-8 md:mb-0">
            <h2 className="text-3xl font-bold colorFontRed mb-4">Llámanos</h2>
            <p className="text-lg">
              Telf:
              <a
                className="link-default no-underline text-white font-bold"
                href="tel:#"
              >
                +51 968 700 858
              </a>
              <br/>
              Telf:
              <a
                className="link-default no-underline text-white font-bold"
                href="tel:#"
              >
                +51 954 175 589
              </a>
              <br/>
              Telf:
              <a
                className="link-default no-underline text-white font-bold"
                href="tel:#"
              >
                +51 947 232 440
              </a>
            </p>
            <p className="text-lg mb-4">
              Encuentra nuestra ubicación y visítanos para conocer nuestra
              maquinaria de calidad al precio justo.
            </p>
            <p className="text-lg mb-4 font-bold">Horario</p>
            <p className="text-lg mb-4">
              Lun a Vie: 8am - 6pm
              <br />
              Sábados: 8am - 1pm
            </p>
          </div>

          {/* Formulario de Contacto */}
          <div className="min-w-[400px] max-w-[500px] md:w-1/2 bg-white text-black p-8 rounded-lg shadow-lg relative z-10">
            {/* <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" htmlFor="name">Nombre*</label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-3 border border-gray-300 rounded"
                  placeholder="Ingrese su nombre aquí"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" htmlFor="phone">Número Celular*</label>
                <input
                  type="text"
                  id="phone"
                  className="w-full p-3 border border-gray-300 rounded"
                  placeholder="Respuesta corta"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" htmlFor="email">Correo electrónico*</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border border-gray-300 rounded"
                  placeholder="Ingrese su correo electrónico"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2" htmlFor="message">Mensaje*</label>
                <textarea
                  id="message"
                  className="w-full p-3 border border-gray-300 rounded"
                  rows="4"
                  placeholder="Escriba su mensaje aquí"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="colorBgRed hover:bg-red-600 text-white font-bold py-3 px-6 rounded-full">
                  Enviar mensaje
                </button>
              </div>
            </form> */}
            <iframe
              src="https://b24-atq8x6.bitrix24.site/crm_form_7yomg/"
              width="100%"
              height="780"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Ubicación en Google Maps"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormData;
