import React from "react";

import Image1 from "../../assets/images/Logos Empresas Clientes/Grupo Centenario.png";
import Image2 from "../../assets/images/Logos Empresas Clientes/INCAONS.png";
import Image3 from "../../assets/images/Logos Empresas Clientes/Los portales.png";
import Image4 from "../../assets/images/Logos Empresas Clientes/MotaEngil.png";
import Image5 from "../../assets/images/Logos Empresas Clientes/TRS.png";
import Image6 from "../../assets/images/Logos Empresas Clientes/comacsa.png";

const images = [
  { src: Image1 },
  { src: Image2 },
  { src: Image3 },
  { src: Image4 },
  { src: Image5 },
  { src: Image6 },
];

const SuccesStoriesImages = () => {
  return (
    <div className="flex justify-center items-center h-[25vh] m-0 colorBgWhite">
      <div className="grid grid-cols-3 gap-x-3 gap-y-10 w-full max-w-5xl">
        {images.map((image, index) => (
          <ImagenEffect key={index} image={image.src} name={`Image${index}`} />
        ))}
      </div>
    </div>
  );
};

export default SuccesStoriesImages;

function ImagenEffect({ image, name }) {
  return (
    <div className="rounded-full relative overflow-hidden shadow-zinc-950/20 shadow-xl  transition-transform duration-1000 ease transform hover:scale-110 hover:filter-none">
      <img
        src={image}
        alt={name}
        className="w-full h-auto filter grayscale transition-transform duration-1000 ease transform hover:scale-110 hover:filter-none"
      />
    </div>
  );
}
