import React from 'react';

function AboutMe() {
  return (
<section id="about-us" className="bg-white py-12">
      <div className="container mx-auto px-6">
      <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Quienes Somos
        </h3>
        <p className="text-center text-gray-700 mt-4 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        En Velimaq, no solo nos dedicamos a la renta y venta de maquinaria y equipo especializado para los sectores de minería y construcción; somos arquitectos de sueños y forjadores de caminos. Nuestra historia comenzó hace más de una década, impulsada por una pasión inquebrantable por la innovación y el progreso. Fundada por visionarios que comprendieron la necesidad de una empresa que no solo ofreciera maquinaria, sino que también proporcionara soluciones integrales y un compromiso inigualable con la excelencia.
        </p>
        <p className="text-center text-gray-700 mt-4 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        Cada miembro de nuestro equipo es una pieza esencial en nuestro engranaje de éxito. Con más de 10 años de experiencia, nuestro equipo altamente calificado se enfoca en brindar un servicio y productos de la más alta calidad, garantizando así la eficiencia y seguridad en cada proyecto de nuestros clientes. No se trata solo de maquinaria; se trata de asegurar que cada proyecto que tocamos se transforme en una obra maestra de precisión y fiabilidad.
        </p>
        <p className="text-center text-gray-700 mt-4 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        Nuestra razón de ser radica en la profunda convicción de que la excelencia no es un acto, sino un hábito. Por ello, hemos adoptado una política de capacitación continua que nos permite cumplir con los más altos estándares de calidad, seguridad y protección del medio ambiente. Nos esforzamos cada día por no solo satisfacer, sino superar las expectativas de nuestros clientes, porque sabemos que en cada desafío hay una oportunidad para demostrar nuestra dedicación y pericia.
        </p>
        <p className="text-center text-gray-700 mt-4 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        En Velimaq, nos enorgullece ser líderes en el mercado, no solo por los productos que ofrecemos, sino por los valores que encarnamos: integridad, innovación y un compromiso ferviente con la satisfacción del cliente. Somos más que una empresa; somos una comunidad de profesionales dedicados a construir un futuro más sólido y seguro, un proyecto a la vez.
        </p>
        <div className="mt-8 flex justify-center space-x-8">
          <div className="text-center">
            <h3 className="text-3xl font-bold text-red-600 counter animate-fadeInUp">+20</h3>
            <p className="text-gray-700 text-lg">Maquinarias Disponibles</p>
          </div>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-red-600 counter animate-fadeInUp" style={{ animationDelay: '0.2s' }}>+10</h3>
            <p className="text-gray-700 text-lg">Años de Experiencia</p>
          </div>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-red-600 counter animate-fadeInUp" style={{ animationDelay: '0.4s' }}>+5000</h3>
            <p className="text-gray-700 text-lg">Horas de Trabajo</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;