import React from "react";
import ProductList from "./ProductList";

function Products() {


  return (
    <div>
      <ProductList />
    </div>
  );
}

export default Products;
