import React from 'react';

import image3 from '../../assets/images/Trabajdores Velimaq/1.webp'
import image2 from '../../assets/images/Trabajdores Velimaq/2.webp'
import image1 from '../../assets/images/Trabajdores Velimaq/3.webp'
import image4 from '../../assets/images/Trabajdores Velimaq/4.webp'

function Team() {
  const teamMembers = [
    {
      name: 'Luis CALIZAYA',
      position: 'INGENIERO INDUSTRIAL',
      image: image1
    },
    {
      name: 'Frydam VASQUEZ',
      position: 'COORDINADOR DE EQUIPOS',
      image: image2
    },
    {
      name: 'Efrain VASQUEZ',
      position: 'SUPERVISOR',
      image: image3
    },
    {
      name: 'Wilfredo REQUIZ',
      position: 'JEFE DE MANTENIMIENTO',
      image: image4
    },
  ];

  return (
    <section id="team" className="bg-white py-12">
      <div className="container mx-auto px-6">
      <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
      Gente y cultura
        </h3>
        <p className="mt-20 text-center text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        En VELIMAQ, nuestra gente es lo que nos hace únicos. En lugar de externalizar a nuestros ingenieros de construcción a establecimientos de dudosa reputación, les proporcionamos un entorno que fomenta el crecimiento profesional y personal. Creemos firmemente en dar voz a nuestros empleados, permitiéndoles ser parte activa en la evolución de la empresa.
        </p>
        <p className="mt-20 text-center text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
        Nuestros equipos se forman y desarrollan con la ayuda de nuestro psicólogo residente, garantizando no solo la máxima productividad, sino también el compromiso y bienestar de cada miembro. En VELIMAQ, cada persona cuenta y su desarrollo integral es nuestra prioridad, porque sabemos que un equipo motivado y bien cuidado es la clave para alcanzar la excelencia en cada proyecto.
        </p>
        <div className="mt-20 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {teamMembers.map((member, index) => (
            <div key={index} className="text-center">
              <img src={member.image} alt={member.name} className="w-32 h-32 rounded-2xl mx-auto"/>
              <h3 className="mt-4 text-base md:text-xl text-gray-800 satisfy">{member.name.split(" ")[0]} <span className='font-bold robotoC'>{member.name.split(" ")[1] }</span></h3>
              <p className="text-gray-600 text-xs md:text-lg">{member.position}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
