import React, { useState } from "react";
import emailjs from "emailjs-com";

import { ReactComponent as FacebookIcon } from "../assets/images/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/images/Instagram.svg";
import { ReactComponent as TiktokIcon } from "../assets/images/TikTok.svg";
import { ReactComponent as LinkedInIcon } from "../assets/images/LinkedIn.svg";

const Footer = () => {
  (function (w, d, u) {
    var s = d.createElement("script");
    s.async = true;
    s.src = u + "?" + ((Date.now() / 60000) | 0);
    var h = d.getElementsByTagName("script")[0];
    h.parentNode.insertBefore(s, h);
  })(
    window,
    document,
    "https://cdn.bitrix24.es/b14126513/crm/site_button/loader_7_a0dhc8.js"
  );

  const [formData, setFormData] = useState({
    email: "",
    message: "Quiero una suscripcion de correo",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_xcltr1n",
        "template_jqjq0f9",
        formData,
        "pvFvNWPgeUFo2TgTS"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Mensaje enviado correctamente");
          setFormData({
            message: "Quiero una suscripcion de correo",
          });
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Error al enviar el mensaje");
        }
      );
  };

  return (
    <footer className=" text-white py-4 colorBgBlue">
      <div className="container ">
        <div className="row md:block">
          <div className="col-md-4 h-44">
            <h5 className="text-danger ">Atención</h5>
            <p>Telf:<a className="link-default no-underline text-white font-bold" href="tel:#">+51 968 700 858</a></p>
            
            <p>Jr Casuarinas 112, Huancayo, Peru</p>
            <p>
              <a href="mailto:mcano@velimaq.com.pe" className="text-white">
                ncano@velimaq.com.pe
              </a>
            </p>
          </div>
          <div className="col-md-4 h-24">
            <h5 className="text-danger">Redes Sociales</h5>

            <div className="flex space-x-4">
              <a href="https://www.facebook.com/VelimaqSac/" className="text-white"  target="_blank" rel="noreferrer">
                <FacebookIcon className="w-6 h-6 fill-current text-white hover:text-blue-800" />
              </a>
              <a href="https://www.instagram.com/velimaq/" className="text-white"  target="_blank" rel="noreferrer">
                <InstagramIcon className="w-6 h-6 fill-current text-white hover:text-pink-800" />
              </a>
              <a href="https://www.tiktok.com/@somosvelimaq" className="text-white"  target="_blank" rel="noreferrer">
                <TiktokIcon className="w-6 h-6 fill-current text-white hover:text-gray-800" />
              </a>
              <a href="https://www.linkedin.com/company/velimaq/" className="text-white"  target="_blank" rel="noreferrer">
                <LinkedInIcon className="w-6 h-6 fill-current text-white hover:text-cyan-800" />
              </a>
            </div>
          </div>
          <div className="col-md-4 h-56">
            <h5 className="text-danger mb-4">Suscríbete a novedades</h5>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3 flex flex-col space-y-4">
                <div className="mb-0">
                  <label
                    className="block text-lg font-semibold mb-2"
                    htmlFor="email"
                  >
                    Correo electrónico*
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full p-3 border border-gray-300 rounded-full text-black"
                    placeholder="Ingrese su correo electrónico"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="colorBgRed justify-center hover:bg-red-600 text-white font-bold py-2 px-3 rounded-full"
                  >
                    Enviar mensaje
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          <p>© 2024. Todos los derechos Reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
