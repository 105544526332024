import React from 'react';

const Hero = () => {
  return (
    <section className="colorBgBlue text-white py-12 relative pt-32">
      <div className="container mx-auto px-6">
        {/* Título y Descripción */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-2">Contacto VELIMAQ</h1>
          <p className="text-lg mb-2">Atención personalizada, entrega a lugar de trabajo, seguimiento, monitoreo.</p>
          <div className="colorFontRed text-lg">★★★★★</div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
