import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MiniFrontal from "../../assets/images/MiniFrontal.webp"; // Asegúrate de usar las rutas correctas
import Excavadora from "../../assets/images/Excavadora.webp";
import { ReactComponent as ArrowIcon } from '../../assets/images/arrows/LeerMas.svg';
import AboutUsAnimacion from "./AboutUsAnimacion";


const AboutUs = () => {
  return (
    <div className="about-us-section colorBgWhite pt-10">
      <div className="container">
        <div className="row align-items-center justify-center">
          <div className="col-md-6 text-center text-md-left">
            <h2 className="robotoC text-3xl colorFontRed font-bold mb-3">
              Quienes Somos
            </h2>
            <p className="inter text-sm mb-4 ">
              Somos una empresa especializada en la renta de maquinaria y equipo
              para la minería y construcción, contando con un equipo humano
              altamente calificado con amplia experiencia en el rubro, enfocados
              en la calidad de servicio y producto para los trabajos de alta
              exigencia de nuestros clientes. Nuestra política de capacitación
              permanente nos lleva a cumplir con altos estándares de calidad,
              seguridad y protección del medio ambiente.
            </p>
            <div className="flex justify-center">
              <button className="inline-flex items-center border-red-600 inter text-red-600 hover:text-gray-900 hover:border-gray-900">
                <span>LEER MÁS</span>
                <ArrowIcon className="w-6 h-6 rounded-full ml-2 fill-current text-red-600 hover:text-gray-900" />
                
              </button>
            </div>
          </div>
          <div className="col-md-6 text-center w-96 flex justify-center">
            <div className="relative">
              {/* Imagen grande */}
              <img
                src={Excavadora}
                className="w-full h-auto rounded-s-full"
                alt="Imagen grande"
              />

              {/* Imagen pequeña*/}
              <img
                src={MiniFrontal}
                className="absolute rounded-bl-3xl -top-1 -right-1 w-1/3 transform translate-x-2/5 -translate-y-1/5"
                alt="Imagen pequeña"
              />
            </div>
          </div>
        </div>
        <div className="row text-center mt-5">
          <div className="col-md-4">
            <h3 className="colorFontRed font-bold azonix text-xl">1580+</h3>
            <p className="robotoC">Horas de trabajo en obras</p>
          </div>
          <div className="col-md-4">
            <h3 className="colorFontRed font-bold azonix text-xl">200+</h3>
            <p className="robotoC">Clientes satisfechos</p>
          </div>
          <div className="col-md-4">
            <h3 className="colorFontRed font-bold azonix text-xl">10+</h3>
            <p className="robotoC">Años de experiencia</p>
          </div>
        </div>
      </div>
      <AboutUsAnimacion />
    </div>
  );
};

export default AboutUs;
