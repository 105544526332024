import React from 'react'

import { ReactComponent as FacebookIcon } from "../../assets/images/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/Instagram.svg";
import { ReactComponent as TiktokIcon } from "../../assets/images/TikTok.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/images/LinkedIn.svg";

function HeaderSocial({classSoc}) {
  const textSize = "w-auto h-4 fill-current text-white sm:h-6 md:h-4 lg:h-5 xl:h-6 "
  return (
    <div>
      <div className={`${classSoc}  `}>
          <a href="https://www.facebook.com/VelimaqSac/" className="text-white" target="_blank" rel="noreferrer"  >
            <FacebookIcon className={`${textSize} hover:text-blue-800`} />
          </a>
          <a href="https://www.instagram.com/velimaq/" className="text-white" target="_blank" rel="noreferrer" >
            <InstagramIcon className={`${textSize} hover:text-pink-800`} />
          </a>
          <a href="https://www.tiktok.com/@somosvelimaq" className="text-white" target="_blank" rel="noreferrer" >
            <TiktokIcon className={`${textSize} hover:text-gray-800`} />
          </a>
          <a href="https://www.linkedin.com/company/velimaq/" className="text-white" target="_blank" rel="noreferrer" >
            <LinkedInIcon className={`${textSize} hover:text-cyan-800`} />
          </a>
        </div>
    </div>
  )
}

export default HeaderSocial;
