import React from 'react';
import ImagenMaquinas from '../../assets/images/maquinaria9.avif'

function Hero() {
  return (
    <section className="bg-gray-900 text-white py-12 pt-32">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Texto y botón */}
          <div className="text-center md:text-left mb-8 md:mb-0 md:w-1/2">
            <h1 className="text-4xl font-bold mb-2">Conócenos</h1>
             <div className="text-lg text-gray-300 mb-4">
              <span className="mr-2">Maquinaria</span>|
              <span className="mx-2">Equipos</span>|
              <span className="ml-2">Vehículos</span>
            </div>
            <a target="_blank" rel="noreferrer" href="https://b24-atq8x6.bitrix24.site/crm_form_7yomg/" className="inline-block colorBgRed hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-full mb-4 md:mb-0">
              Contáctanos
            </a>
            <div className="text-sm text-gray-400 mt-2">
              <span className="colorFontRed">★★★★★</span>
              <span className="ml-2">Más de 10+ años de experiencia</span>
            </div>
          </div>
          {/* Imágenes de Maquinaria */}
          <div className="flex flex-wrap justify-center md:w-1/2">
            {/* <img src="/path/to/machinery1.png" alt="Machinery 1" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery2.png" alt="Machinery 2" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery3.png" alt="Machinery 3" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery4.png" alt="Machinery 4" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery5.png" alt="Machinery 5" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery6.png" alt="Machinery 6" className="w-20 h-20 mx-2 mb-4" />
            <img src="/path/to/machinery7.png" alt="Machinery 7" className="w-20 h-20 mx-2 mb-4" /> */}
            <img src={ImagenMaquinas} alt="Machinery 8" className="w-max h-max mx-2 mb-4" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
