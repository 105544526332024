import React from "react";
import imagen1 from "../../assets/images/C-america.svg";
import imagen2 from "../../assets/images/C-teamPresentation6.svg";
import imagen3 from "../../assets/images/C-teamPresentation.svg";

function Team() {
  return (
    <section id="team" className="bg-white py-12">
      <div className="container mx-auto px-6">
        <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Misión
        </h3>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-6 items-center px-10 md:px-0">
          <p className=" col-span-3 my-10 text-justify text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
            En Velimaq, nuestra misión es liderar el mercado de alquiler y venta
            de maquinaria pesada, proporcionando un ciclo continuo de renovación
            y actualización de nuestros equipos. Nos dedicamos a satisfacer las
            necesidades de empresas de todos los tamaños en el sector privado,
            ofreciendo una amplia gama de maquinaria pesada, incluyendo
            excavadoras, cargadores frontales, camiones tracto,
            retroexcavadoras, aplanadoras y motoniveladoras. Nuestro objetivo es
            ser el socio confiable que asista a nuestros clientes en sus
            proyectos de construcción y remodelación, brindándoles soporte
            técnico especializado, precios competitivos, y facilidades de pago
            que aseguren su éxito.
          </p>
          <img
            src={imagen1}
            alt="trabajo en equipo"
            className="w-full mx-auto col-span-2 hidden md:block"
          />
        </div>
        <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Visión
        </h3>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-6 items-center  px-10 md:px-0">
          <img
            src={imagen2}
            alt="trabajo en equipo"
            className="col-span-2 w-full mx-auto hidden md:block"
          />
          <p className="col-span-3 my-10 text-justify text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
            Nuestra visión es ser una empresa reconocida a nivel nacional,
            estableciendo sucursales estratégicas en diversos puntos del país
            para expandir nuestro alcance y servicio. Aspiramos a ser sinónimo
            de excelencia en el alquiler y venta de maquinaria pesada, innovando
            continuamente para mejorar nuestra oferta y adaptarnos a las
            necesidades del mercado. Buscamos fomentar relaciones sólidas y
            duraderas con nuestros clientes, empleados y socios, fundamentadas
            en la confianza y el compromiso mutuo. Deseamos ser líderes en la
            industria, destacándonos por nuestra capacidad de proporcionar
            soluciones eficientes y de alta calidad que impulsen el desarrollo y
            crecimiento de nuestros clientes y de la sociedad.
          </p>
        </div>
        <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Valores
        </h3>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-10 items-center  px-10 md:px-0">
          <p className="col-span-3 text-justify text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
            <span className="font-bold pr-2 block mt-3 ">Integridad:</span>
            Actuamos con honestidad y transparencia en todas nuestras
            operaciones y relaciones.
            <br />
            <span className="font-bold pr-2 block mt-3 ">Calidad:</span>
            Mantenemos altos estándares en nuestros productos y servicios,
            garantizando la satisfacción del cliente.
            <br />
            <span className="font-bold pr-2 block mt-3 ">Innovación:</span>
            Buscamos constantemente mejorar e innovar en nuestros procesos y
            tecnologías.
            <br />
            <span className="font-bold pr-2 block mt-3 ">
              Equidad e Igualdad de Género:
            </span>
            Reconocer que todas las personas merecen el mismo trato de igualdad
            y respeto, consideración personal y mismos derechos ante la ley y la
            sociedad. Asimismo, prohibir el uso de mano de obra infantil y uso
            de mano de obra forzada.
            <br />  
            <span className="font-bold pr-2 block mt-3 ">
              SocioAmbiental:
            </span>
            Operamos con responsabilidad social y ambiental, contribuyendo al
            bienestar de nuestra comunidad.
            <br />
            <span className="font-bold pr-2 block mt-3 ">
              Trabajo en equipo:
            </span>
            Fomentamos un ambiente de colaboración y respeto, valorando las
            contribuciones de cada miembro de nuestro equipo.
          </p>
          <img
            src={imagen3}
            alt="trabajo en equipo"
            className="col-span-2 w-full mx-auto hidden md:block"
          />
        </div>
      </div>
    </section>
  );
}

export default Team;
