import React from 'react';
import Hero from './Hero';
import Form from './Form';
import Location from './Location';

const Contact = () => {

  return (
    <>
        <Hero />        
        <Form />
        <Location />

    </>
  );
};

export default Contact;


