import React from 'react';

const Hero = () => {
  return (
    <section className="colorBgBlue text-white py-12 relative pt-52">
      <div className="container mx-auto px-6">
        {/* Título y Descripción */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-2">Nuestros Productos</h1>
          <p className="text-lg mb-2">Disponemos de una amplia gama de maquinaria, así como operadores para todos los requerimientos, y para todo tipo de proyecto.</p>
          <div className="colorFontRed text-lg">★★★★★</div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
