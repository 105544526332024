import React from "react";

function PrivacyPolicy() {
  return (
    <section id="privacy-policy" className="bg-white py-12 pt-28">
      <div className="container mx-auto px-6">
        <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Política de Privacidad
        </h3>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-6 items-center px-10 md:px-0">
          <p className="col-span-5 my-10 text-justify text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
            <strong>1. Introducción</strong>
            <br />
            En Velimaq, valoramos su privacidad y estamos comprometidos a proteger su información personal. Esta Política de Privacidad describe cómo recopilamos, usamos, y compartimos su información cuando utiliza nuestra aplicación y servicios relacionados.
            <br /><br />
            <strong>2. Información que Recopilamos</strong>
            <br />
            Cuando utiliza nuestra aplicación, podemos recopilar los siguientes tipos de información:
            <br />
            - <strong>Información de Registro:</strong> Nombre, dirección de correo electrónico, número de teléfono, y otros datos que proporcione durante el proceso de registro.
            <br />
            - <strong>Información del Dispositivo:</strong> Información sobre el dispositivo que utiliza para acceder a nuestra aplicación, como el modelo del dispositivo, el sistema operativo y el identificador único del dispositivo.
            <br />
            - <strong>Información de Uso:</strong> Datos sobre cómo utiliza la aplicación, incluyendo las páginas que visita, el tiempo que pasa en cada página y otras actividades en la aplicación.
            <br /><br />
            <strong>3. Uso de la Información</strong>
            <br />
            Usamos la información recopilada para:
            <br />
            - Proporcionar y mejorar nuestros servicios.
            <br />
            - Personalizar su experiencia en la aplicación.
            <br />
            - Comunicarnos con usted, incluyendo responder a sus consultas y enviarle actualizaciones y notificaciones importantes.
            <br />
            - Analizar el uso de la aplicación y realizar mejoras.
            <br /><br />
            <strong>4. Compartir Información</strong>
            <br />
            No compartimos su información personal con terceros, excepto en las siguientes circunstancias:
            <br />
            - Con su consentimiento explícito.
            <br />
            - Para cumplir con leyes o regulaciones aplicables.
            <br />
            - Para proteger la seguridad, los derechos y la propiedad de Velimaq, nuestros usuarios, o el público.
            <br /><br />
            <strong>5. Seguridad de la Información</strong>
            <br />
            Implementamos medidas de seguridad razonables para proteger su información personal contra el acceso no autorizado, la pérdida, el uso indebido o la divulgación.
            <br /><br />
            <strong>6. Retención de Información</strong>
            <br />
            Conservamos su información personal solo durante el tiempo que sea necesario para cumplir con los propósitos para los cuales fue recopilada, a menos que la ley exija o permita un período de retención más prolongado.
            <br /><br />
            <strong>7. Sus Derechos</strong>
            <br />
            Usted tiene derecho a acceder, corregir, actualizar o eliminar su información personal. Para ejercer estos derechos, puede ponerse en contacto con nosotros en el correo electrónico proporcionado en la sección de contacto.
            <br /><br />
            <strong>8. Cambios a esta Política de Privacidad</strong>
            <br />
            Podemos actualizar esta Política de Privacidad periódicamente para reflejar cambios en nuestras prácticas o en la legislación aplicable. Le notificaremos cualquier cambio importante a través de la aplicación o por otros medios apropiados.
            <br /><br />
            <strong>9. Contacto</strong>
            <br />
            Si tiene alguna pregunta sobre esta Política de Privacidad o sobre nuestras prácticas de privacidad, puede contactarnos en:
            <br />
            - <strong>Correo Electrónico:</strong> privacidad@velimaq.com
            <br />
            - <strong>Dirección:</strong> Av. de la Empresa, 1234, Ciudad, País
          </p>
        </div>

        <h3 className="satisfy text-7xl font-bold text-center text-red-500 mt-5">
          Información de la App
        </h3>
        <div className="grid md:grid-cols-5 grid-cols-1 gap-6 items-center px-10 md:px-0">
          <p className="col-span-5 my-10 text-justify text-gray-700 text-sm sm:text-sm md:text-sm lg:text-lg xl:text-xl">
            - <strong>Nombre de la App:</strong> Velimaq App
            <br />
            - <strong>Descripción:</strong> La Velimaq App permite a los usuarios alquilar y comprar maquinaria pesada, así como acceder a servicios de reparación y mantenimiento.
            <br />
            - <strong>Versión:</strong> 1.0.0
            <br />
            - <strong>Desarrollador:</strong> Velimaq Inc.
            <br />
            - <strong>Sitio Web:</strong> <a href="http://www.velimaq.com.pe" className="text-blue-500">www.velimaq.com.pe</a>
            <br />
            - <strong>Correo de Soporte:</strong> soporte@velimaq.com.pe
            <br /><br />
            <strong>Características Principales:</strong>
            <br />
            - Alquiler y compra de maquinaria pesada.
            <br />
            - Servicios de reparación y mantenimiento.
            <br />
            - Soporte al cliente en tiempo real.
            <br />
            - Notificaciones sobre ofertas y promociones.
            <br /><br />
            <strong>Permisos Requeridos:</strong>
            <br />
            - <strong>Acceso a la Cámara:</strong> Para permitir la captura de imágenes de la maquinaria.
            <br />
            - <strong>Acceso al Micrófono:</strong> Para habilitar la comunicación con el soporte técnico.
            <br />
            - <strong>Ubicación:</strong> Para proporcionar servicios basados en la localización.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
