import React from 'react'
import logo from "../../assets/images/logow.webp";

function HeaderLogo() {
  return (
    <div>
      <img src={logo} alt="Velimaq Logo" className="h-20 md:h-10 ml-4 md:ml-0" />
        
    </div>
  )
}

export default HeaderLogo
