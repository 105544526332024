import React from 'react';

const Location = () => {
  return (
    <div className="container my-5">
      <div className="col-md-6">
          <h2 className="text-danger">Ubícanos:</h2>
          <br/>
          </div>
        <div className="container">
  <div className="row">

    <div className="col-md-6 col-12 mb-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title robotoC font-bold">Azapampa - Huancayo - JUNIN</h5>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31337.783002232362!2d-75.1870608329773!3d-12.109173849072898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x910e964f66445555%3A0xbfbdfcd19417b8a2!2sAv.%20Circunvalacion%2C%2012000%2C%20Per%C3%BA!5e0!3m2!1en!2spe!4v1690396060622!5m2!1en!2spe"
            width="100%"
            height="300"
            
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Ubicación en Google Maps"
          ></iframe>
        </div>
      </div>
    </div>

    <div className="col-md-6 col-12 mb-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title robotoC font-bold">Av Las Casuarinas 245 - Chilca - LIMA</h5>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31337.783002232362!2d-76.73574049025774!3d-12.475923518084889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x910e964f66445555%3A0xbfbdfcd19417b8a2!2sAv%20Las%20Casuarinas%20245%2C%20Chilca%2015870%2C%20Per%C3%BA!5e0!3m2!1en!2spe!4v1690396060622!5m2!1en!2spe"
            width="100%"
            height="300"
           
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Ubicación en Google Maps"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default Location;
