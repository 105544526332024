import React from "react";
import alquilerImage from "../../assets/images/Servicios1.webp";
import mantenimientoImage from "../../assets/images/servicios2.webp";
import personalImage from "../../assets/images/servicios3.webp";

const Services = () => {
  return (
    <div className="services-section py-5 bg-dark text-white">
      <div className="container">
        <div className="text-center mb-5">
          <h2 className="colorFontRed font-bold">Nuestros Servicios</h2>
          <p className="mt-3">
            El precio justo, servicio técnico de acompañamiento con la mejor
            calidad de procesos. Contamos con maquinaria pesada para el sector
            industrial (construcción y minería), disponemos de una amplia gama
            de maquinaria, así como operadores para todos los requerimientos y
            para todo tipo de proyecto.
          </p>
        </div>
        <div className="row">
          <CardComponent
            imageSrc={alquilerImage}
            title="Alquiler de Maquinaria Pesada"
            text="Contamos con una gran variedad de Maquinarias y Equipos para Construcción nuevos y usados de diferentes marcas en Perú, cumpliendo con los estándares de calidad internacionales de la industria. Consulte el precio y cotiza el modelo ideal en nuestra web."
          />

          <CardComponent
            imageSrc={mantenimientoImage}
            title="Mantenimiento de Maquinaria Pesada"
            text="Confiar es bueno, por eso en VELIMAQ cuidamos cada detalle
                  para que tu experiencia post venta sea la que mereces."
          />

          <CardComponent
            imageSrc={personalImage}
            title="Atencion Personal Técnico Calificado"
            text="Todos nuestros técnicos, asesores y gerentes cuentan con la
                  preparación profesional para brindar un servicio de calidad.
                  Nuestros clientes cuentan con una atención personalizada y
                  especialmente preparada de su maquinaria."
          />
        </div>
      </div>
    </div>
  );
};

export default Services;

const CardComponent = ({ imageSrc, title, text }) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card h-100 border-0">
        <img
          src={imageSrc}
          className="card-img-top rounded"
          alt={title}
          style={{ height: "300px", objectFit: "cover" }} // Fija la altura de la imagen y ajusta el tamaño para mantener la proporción
        />
        <div className="h-auto card-body bg-light text-dark rounded-bottom">
          <h5 className="px-3 card-title font-bold text-center mb-3 text-2xl ">{title}</h5>
          <p className="card-text mt-5 px-3 text-justify">{text}</p>
        </div>
      </div>
    </div>
  );
};
