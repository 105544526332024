// src/components/HomePage.js
// <source src="https://velimaq.smartaccesorios.shop/HeroPaginaWeb.webm" type="video/webm" />
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.css";
import videoHero from "../../assets/videos/HeroPaginaWeb.webm";

const Hero = () => {
  return (
    <div className="relative bg-gray-800 text-white h-screen">
      <div className="relative h-full">
        <video autoPlay muted loop
          className="absolute top-0 left-0 w-full h-full object-cover opacity-70"
        >
          <source src={videoHero} type="video/webm" />
        </video>
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-start pl-10 justify-center bg-black/55 bg-opacity-30">
          <h1 className="pt-5 text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold colorFontRed azonix">
            <span className="colorFontRed ">MAQUINARIA</span>
            <hr />
            <span className="colorFontRed ">PESADA</span>
            <hr />
            <span className="colorFontWhite ">PARA TUS</span>
            <hr />
            <span className="colorFontWhite ">PROYECTOS</span>
          </h1>
          <button className="mt-4 px-4 py-2 colorBgRed text-white rounded-full text-base md:text-xl lg:text-1xl xl:text-2xl">
            Contacto
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
