import React from 'react'
import useMediaQuery from "./useMediaQuery"

function HeaderButton({classBut}) {

  const isMediumOrLarger = useMediaQuery(1000);

  return (

    <>
    {
      isMediumOrLarger &&
      (<div className='hidden md:flex'>
         <a
            target="_blank" rel="noreferrer"
            href="https://b24-atq8x6.bitrix24.site/crm_form_7yomg/"
            className={`${classBut} inter colorBgRed text-white px-4 py-2 rounded-full no-underline text-lg md:text-sm lg:text-base xl:text-xl`}
          >
            Cotiza Aquí
          </a>
      </div>)
    }
    </>
  )
}

export default HeaderButton
