import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="flex">
      <div className="w-1/6 flex flex-col items-center">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={`m-1 p-1 w-full h-auto object-cover mb-2 cursor-pointer ${
              currentImageIndex === index ? "border-2 border-blue-500" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
      <div className="w-5/6 flex items-center justify-center relative">
        <ChevronLeftIcon
          className="w-8 h-8 absolute left-2 cursor-pointer z-10"
          onClick={handlePrevClick}
        />
        <img
          src={images[currentImageIndex]}
          alt={`Main ${currentImageIndex + 1}`}
          className="w-auto h-full object-contain"
        />
        <ChevronRightIcon
          className="w-8 h-8 absolute right-2 cursor-pointer z-10"
          onClick={handleNextClick}
        />
      </div>
    </div>
  );
};

export default ImageGallery;
